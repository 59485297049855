@import "../../custom.scss";

.footer-general {
  font-family: "Montserrat", sans-serif;
  font-size: clamp(16px, 1.5vw, 1.5rem);
  background: rgba(253, 253, 253, 0.5);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: #000000;
  width: 100% !important;
  margin-top: 5rem;
  padding: 15px;
  text-decoration: none;
}

.footer-options {
  justify-content: center;
}

.footer-options-icons {
  padding: 10px;
}

.footer-copyright {
  padding-left: 30%;
  padding-right: 30%;
  text-align: center;
  font-size: clamp(16px, 1vw, 1rem);
}

.footer-description {
  font-size: clamp(16px, 1.5vw, 1.5rem);
  margin-top: 1rem;
  padding: 8px;
}

.footer-social {
  font-size: clamp(16px, 1.5vw, 1.5rem);
  padding: 8px;
  p{
    font-weight: 700;
  }
}

.footer-contact {
  font-size: clamp(16px, 1.5vw, 1.5rem);
  padding: 8px;
  p{
    font-weight: 700;
  }
  
}

.footer-contact-special {
  text-align: left;
  text-align: center;
  font-size: clamp(12px, 1.5vw, 1.5rem);
  p{
    font-weight: 700;
  }
}



.mail {
  font-size: clamp(16px, 1vw, 1.5rem);
  color: black !important;
  padding: 1rem;
}
.separator {
  margin-left: 10px;
  margin-right: 10px;
}

.fa {
  border: 2px;
  padding: 20px;
  font-size: clamp(16px, 1.5vw, 1.5rem);
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  border-color: white;
  color: $light1;
  &:hover {
    color: $light2 !important;
  }
}
