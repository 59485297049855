.App {
  background: url("./resources/background.svg") no-repeat center center fixed;
  /* text-align: center; */
  color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: auto;
  min-width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.posts {
  list-style-type: none;
}

.post {
  list-style-type: none;
  
}

.creator-title {
  display: inline-block;
}

.halfsize {
  -moz-transform:scale(0.75);
  -webkit-transform:scale(0.55);
  transform:scale(0.75);
}

.space-right {
  margin-right: 1rem;
}

.control-panel {
  justify-content: space-between;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.blogs-container {
  display: block;
  align-items: center;
}

.tab {
  margin-left: 3rem;
}

.inlineBlockOnMobile {
  display: inline; /* On desktop, these are inline */
}

/* Adjust the 600px to your desired breakpoint */
@media (max-width: 600px) {
  .inlineBlockOnMobile {
    display: block; /* On mobile, these are block */
  }
}
