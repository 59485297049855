p special-p {
  white-space: pre;
}

.max-width {
  max-width: 100vw;
}

.card-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-img-container {
  height: auto;
  width: auto;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-img-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit:fill;
}

.control-panel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-blogs {
  display: flex;
  flex-direction: column;
}

.panel {
  padding: 0.5rem;
  margin: 0.2rem;
}

.btn-blog {
  margin: 1rem;
  padding: 1rem;
  width: clamp(100px, 20vw, 200px);
  font-size: clamp(12px, 2vw, 20px);
}

.card-text {
  padding: 1rem;
  font-size: 1.2rem;
}

.blockquote-footer{
    font-size: clamp(12px, 1vw, 15px) !important;
}

.blogs-display{
    margin: auto !important;
}


.fixed-img-size {
  width: auto;  /* or you can use any other size in px, em, rem, etc. */
  height: auto; /* to maintain aspect ratio */
}

.fixed-card-body {
  height: auto;  /* you can adjust this based on your needs */
  overflow: auto; /* add scroll if text is longer */
}
