@import "../../custom";

.blurry {
  backdrop-filter: blur(12px) saturate(100%);
  background-color: rgba(245, 244, 241, 0.25);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  min-height: 5vh;
  padding: 0.5rem;
}

.align-it {
  align-items: center !important;
  color: black;
  .main {
    &:hover {
      transform: scale(1.1);
    }
  }

  .newsletter {
    background-color: $light2;
    color: $black;
    font-size: 1.2rem;
    font-weight: 700;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: none;
    margin-left: 1rem;
    margin-right: 1rem;
    cursor: pointer;
    &:hover {
      background-color: $light;
      color: $dark;
    }
  }
}

.navbar-align2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  height: 3rem;
}

.dropItem {
  color: black !important;
  
  &:hover {
    color: black !important;
    font-weight: 700;
  }
}
.dropdown-item {
  color: black !important;
  &:hover {
    color: black !important;
    background-color: $light !important;
    font-weight: 700;
  }
}
